import { graphql } from 'gatsby';
import React, { useState, useMemo } from 'react';
import { Parallax, ParallaxLayer } from '@react-spring/parallax';
import Layout from '../components/layout/layout';

import '../assets/stylesheets/pages/index.scss';

export default function HomePage({ data }) {
  return (
    <Layout pages="1.22">
      <ParallaxLayer
        offset={0}
        speed={0.8}
        className="save-the-date p-layer"
        factor={2}
      >
        <h1 className="huge">Save the Date</h1>
      </ParallaxLayer>

      <ParallaxLayer
        offset={0.48}
        speed={1.2}
        factor={0.4}
        className="the-date p-layer"
      >
        <h1 className="huge">Aug 06 2022</h1>
      </ParallaxLayer>

      <ParallaxLayer
        offset={0.88}
        speed={1.5}
        factor={0.37}
        className="our-names p-layer"
      >
        <h1 className="huge">Corina & Andre</h1>
      </ParallaxLayer>

      <ParallaxLayer
        offset={0.97}
        speed={0.25}
        factor={0.35}
        className="city p-layer"
      >
        <h1 className="huge">Toronto</h1>
      </ParallaxLayer>
    </Layout>
  );
}

// Two options
// one we make one query that pre sorts both types for us -
// or what I'm leaning towards is interweave the two.
// How do you do that
// We know both lists are in order.  Next item is guaranteed to be higher than current.
// Grab first item from first list.  Grab first item from second list.
// Compare.  If first is first put it in new list.
// If second list item is first, put that in the new list.
// Advance the list you were in.  Compare. Put the appropriate item in the new list.
// If inner loop is advanced, the inner array must shrink so that when its looped again it will be where it left off

export const query = graphql`
  query ($skip: Int = 0, $pageSize: Int = 100) {
    allImages: allFile(
      filter: { sourceInstanceName: { eq: "images" } }
      sort: { order: DESC, fields: birthTime }
    ) {
      edges {
        node {
          name
          birthTime
          childImageSharp {
            small: gatsbyImageData(width: 200)
            big: gatsbyImageData(width: 400)
          }
        }
      }
    }
    allPosts: allMarkdownRemark(limit: $pageSize, skip: $skip) {
      edges {
        node {
          html
          frontmatter {
            publishDate
          }
        }
      }
    }
  }
`;
