import React from 'react';
import { Parallax, ParallaxLayer } from '@react-spring/parallax';
import SEO from '../SEO';

export default function Layout({ pages, children }) {
  return (
    <>
      <SEO title="Corina & Andre - August 6th 2022 - Toronto" />
      <Parallax pages={pages} style={{ top: '0', left: '0' }}>
        {children}
      </Parallax>
    </>
  );
}
